import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import Header from "../components/soluciones/Header"

const Solution = ({ data, location }) => {
  const solution = data.solutionsJson

  return (
    <Layout location={location}>
      <SEO title={solution.title} />
      <Header
        title={solution.title}
        subtitle={solution.subtitle}
        logo={solution.logo}
        background={solution.header && solution.header.childImageSharp.fixed}
        colors={solution.colors}
      />
      <Body>
        <div className="card">
          <div>
            <h2>La problemática</h2>
            <div dangerouslySetInnerHTML={{ __html: solution.problem }} />
          </div>
          <div>
            <h2>La idea</h2>
            <div dangerouslySetInnerHTML={{ __html: solution.idea }} />
          </div>
          <div>
            <h2>La solución</h2>
            <div dangerouslySetInnerHTML={{ __html: solution.solution }} />
          </div>
        </div>
        <div>
          <div>
            {
              solution.product &&
              <>
                <h2>El producto</h2>
                <div dangerouslySetInnerHTML={{ __html: solution.product }} />
              </>
            }
            {
              solution.service &&
              <>
                <h2>El servicio</h2>
                <div dangerouslySetInnerHTML={{ __html: solution.service }} />
              </>
            }
            {
              solution.software &&
              <>
                <h2>El software</h2>
                <div dangerouslySetInnerHTML={{ __html: solution.software }} />
              </>
            }
            {
              solution.platform &&
              <>
                <h2>La plataforma</h2>
                <div dangerouslySetInnerHTML={{ __html: solution.platform }} />
              </>
            }
            <Image
              fluid={solution.image.childImageSharp.fluid}
              alt={solution.title}
            />
          </div>
        </div>
      </Body>
    </Layout>
  )
}
export default Solution

const Body = styled.div`
  @media screen and (max-width: 767px){
    flex-direction: column;
    padding: 2rem 1rem;
  }
  display: flex;
  box-sizing: border-box;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  @media screen and (min-width: 1200px){
    width: 90%;
  }
  > div{
    @media screen and (min-width: 768px){
      width: 50%;
      padding: 1rem 3rem 0;
    }
  }
  .card{
    @media screen and (max-width: 767px){
      margin-bottom: 3rem;
      padding: 2rem 2rem;
    }
    border-radius: 1rem;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    margin-top: -5rem;
    z-index: 11;
    background-color: #fff;
    padding: 2.75rem 3rem;
  }
  h2{
    font-size: 1.125rem;
    color: ${props => props.theme.colors.icon.primary};
    @media screen and (min-width: 768px){
      font-size: 1.5rem;
    }
  }
  p{
    font-size: 0.875rem;
    line-height: 2em;
    color: ${props => props.theme.colors.text.secondary};
    @media screen and (min-width: 768px){
      font-size: 1.125rem;
    }
  }
  ul{
    list-style: none;
    margin: 0;
    li{
      font-size: 0.875rem;
      @media screen and (min-width: 768px){
        font-size: 1.125rem;
      }
      color: ${props => props.theme.colors.text.secondary};
      line-height: 2em;
      position: relative;
      &:before{
        content: '';
        background-color: ${props => props.theme.colors.icon.primary};
        width: 7px;
        height: 7px;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: .65rem;
        left: -1rem;
        @media screen and (min-width: 768px){
          left: -1.5rem;
          width: 10px;
          height: 10px;
          top: .8rem;
        }
      }
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    solutionsJson(slug: { eq: $slug }) {
      title
      subtitle
      problem
      idea
      solution
      colors
      product
      service
      software
      platform
      logo
      header {
        childImageSharp {
          fixed(width: 1920, height: 400){
            ...GatsbyImageSharpFixed
          }
        }
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`