import React from "react"
import Sigthwise from "../../../static/svg/logo-sightwise.svg"
import Doxtor from "../../../static/svg/logo-doxtor.svg"
import Bleo from "../../../static/svg/logo-bleo.svg"
import Video from "../../../static/svg/logo-videollamadadeemergencia.svg"
import Goldbeet from "../../../static/svg/logo-goldbeet.svg"
import LakeIt from "../../../static/svg/logo-lakeit.svg"
import Metrically from "../../../static/svg/logo-metrically.svg"
import Bimov from "../../../static/svg/logo-bimov.svg"
import Ventsupport from "../../../static/svg/logo-ventsupport.svg"

const BrandLogo = ({ brand }) => {
  switch (brand) {
    case 'logo-sightwise.svg':
      return <Sigthwise />;
    case 'logo-doxtor.svg':
      return <Doxtor />;
    case 'logo-goldbeet.svg':
      return <Goldbeet />;
    case 'logo-bleo.svg':
      return <Bleo />;
    case 'logo-bimov.svg':
      return <Bimov />;
    case 'logo-ventsupport.svg':
      return <Ventsupport />;
    case 'logo-lakeit.svg':
      return <LakeIt />;
    case 'logo-metrically.svg':
      return <Metrically />;
    case 'logo-videollamadadeemergencia.svg':
      return <Video />;
    default:
      return null;
  }
}
export default BrandLogo
