import React from "react"
import styled, { css } from 'styled-components'
import Image from "gatsby-image"
import BrandLogo from "../soluciones/BrandLogo"
import { useWindowSize } from "../../components/WindowSize"


const Header = ({ title, subtitle, logo, background, colors }) => {
  const size = useWindowSize()
  return (
    <StyledHeader variant={logo && 'logo'} colors={colors}>
      <div className="container">
        <div className="brand-container">
          <div className="brand">
            {
              logo ? <BrandLogo brand={logo} /> : <h1>{title}</h1>
            }
            {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle }} />}
          </div>
        </div>
      </div>
      {
        size.width > 767 &&
        <Image
          className="image"
          style={{ width: '100%', display: 'block' }}
          fixed={background}
          alt={title}
          cropFocus='center'
        />
      }
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  position: relative;
  .container{
    @media (min-width: 768px){
      position: absolute;
      z-index: 10;
      top: 0;
      height: 100%;
      width: 100%;
    }
    display: flex;
    align-items: center;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      height: 100%;

      ${({ variant }) => variant === 'logo' ?
    css`background: ${({ colors }) => colors.length > 1 ? `linear-gradient(137deg, ${colors[0]} 0%, ${colors[1]} 100%);` : `${colors[0]};`}
      width: 100%;
      @media screen and (min-width: 768px){
        clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
        width: 55%;
      }
      @media screen and (min-width: 2000px){
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
      }
      ` :
    css`
      background: ${({ colors }) => colors[0]};
      width: 100%;
      @media screen and (min-width: 768px){
      background: ${({ colors }) => colors.length > 1 ? `linear-gradient(136deg, ${colors[0]} 40%, ${colors[1]} 80%);` : `linear-gradient(136deg, #3DA8A3 0%, rgba(0,255,198,0) 80%);`}
      }
      `}
    }
    .brand-container{
      box-sizing: border-box;
      padding: 2rem 1rem 4rem;
      max-width: 1400px;
      margin: 0 auto;
      width: 100%;
      position: relative;
      text-align: center;
      @media screen and (min-width: 768px){
        padding: 2rem;
        text-align: left;
      }
      @media screen and (min-width: 1200px){
        width: 90%;
      }
      .brand{
        display: flex;
        flex-direction: column;
        max-width: 600px;
        @media screen and (max-width: 767px){
          justify-content: center;
          min-height: 180px;
          align-items: center;
        }
        svg{
          @media screen and (max-width: 767px){
            max-width:200px;
          }
        }
        h1{
          color: ${props => props.theme.colors.text.light};
          font-size: 2rem;
          line-height: 1.1em;
          margin-bottom: .5rem;
          @media screen and (min-width: 768px){
            font-size: 3.625rem;
            line-height: 1.1em;
          }
        }
        p{
          color: ${props => props.theme.colors.text.light};
          font-size: 1rem;
          line-height: 1.1em;
          @media screen and (min-width: 768px){
            font-size: 1.5rem;
            line-height: 1.1em;
          }
        }
      }
    }
  }
`

export default Header